.wallet-container {
  padding-top: 200px;
  padding-bottom: 250px;
  background: linear-gradient(180deg, rgba(125, 21, 227, 0.00) 0%, rgba(125, 21, 227, 0.40) 18.26%, rgba(125, 21, 227, 0.40) 80.21%, rgba(125, 21, 227, 0.00) 100%);
}

.wallet-container .wallet-focus .title {
  color: #ECECEC;
  text-align: center;
  /*font-family: DingTalk JinBuTi;*/
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.wallet-container .wallet-focus .sub-title {
  max-width: 760px;
  margin: 34px auto 97px;
  color: #ECECEC;
  text-align: center;
  /*font-family: DingTalk JinBuTi;*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.wallet-container .wallet-focus .sub-titleEn {
  font-size: 24px;
}

.wallet-container .wallet-list {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.wallet-container .wallet-list .wallet-item {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.wallet-container .wallet-list .wallet-item.reverse {
  flex-direction: row-reverse;
}

.wallet-container .wallet-list .wallet-item img {
  max-width: 520px;
  width: 100%;
  height: auto;
  display: block;
}

.wallet-container .wallet-list .wallet-item figcaption {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.wallet-container .wallet-list .wallet-item figcaption .title {
  color: #ECECEC;
  /*font-family: DingTalk JinBuTi;*/
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.wallet-container .wallet-list .wallet-item figcaption .desc {
  color: #ECECEC;
  font-family: 'FZZhongKai-B08T';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.wallet-container .wallet-list .wallet-item figcaption .descEn {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .wallet-container .wallet-focus {
    padding: 0 20px;

  }
}

@media (max-width: 980px) {

  .wallet-container .wallet-focus .title {
    font-size: 36px;
  }

  .wallet-container .wallet-focus .sub-title {
    font-size: 16px;
  }

  .wallet-container .wallet-list .wallet-item {
    gap: 40px;


  }

  .wallet-container .wallet-list .wallet-item figcaption .title {
    font-size: 24px;
  }


  .wallet-container .wallet-list .wallet-item img {
    max-width: 400px;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .wallet-container{
    padding-bottom: 134px;
  }
  .wallet-container .wallet-focus .sub-title {
    margin: 34px auto 60px;
  }

  .wallet-container .wallet-list {
    gap: 60px;
  }


  .wallet-container .wallet-list .wallet-item {
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;

  }

  .wallet-container .wallet-list .wallet-item img {
    max-width: 325px;
    width: 100%;
    height: auto;
  }

  .wallet-container .wallet-list .wallet-item.reverse {
    flex-direction: column;
  }

  .wallet-container .wallet-list .wallet-item figcaption {
    width: calc(100% - 40px);
  }

  .wallet-container .wallet-list .wallet-item figcaption .title {
    text-align: left;
  }
}