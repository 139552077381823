.download-container {
	max-width: 1200px;
	margin: 0 auto;
	padding-top: 172px;
}

.download-container .title {
	color: #ececec;
	/* font-family:FZZhongKai-B08T; */
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.download-container .qr-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 100px;
	padding: 80px 0;
}

.download-container .qr-list li {
	flex: 1;
}

.download-container .qr-list li:nth-child(2) {
	position: relative;
}

.download-container .qr-list li:nth-child(2)::before {
	content: '';
	position: absolute;
	width: 1px;
	height: 100%;
	background: linear-gradient(180deg,
			rgba(255, 255, 255, 0) 0%,
			#fff 50.34%,
			rgba(255, 255, 255, 0) 100%);
}

.download-container .qr-list li figure {
	max-width: 224px;
	margin: 0 auto;
	padding: 12px;
	box-sizing: border-box;
	border-radius: 24px;
	text-align: center;
	overflow: hidden;
	border-radius: 24px;
	background: #32014b;
}

.download-container .qr-list li figure .qr {
	width: 200px;
	height: 200px;
	object-fit: contain;
}

.download-container .qr-list li figure .icon {
	width: auto;
	height: 40px;
	margin-bottom: 12px;
}

.download-container .qr-list li:first-child figure {
	background: #072256;
}

.download-container .qr-list li figure figcaption {
	margin-bottom: 12px;
	color: rgba(255, 255, 255, 0.7);
	text-align: center;
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.18px;
}

.download-container .qr-list li figure figcaption em {
	color: #fff;
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.18px;
}

@media (max-width: 1200px) {
	.download-container {
		padding: 0 20px;
	}
}

@media (max-width: 980px) {
	.download-container {
		padding: 0 20px;
	}

	.download-container .title {
		font-size: 32px;
	}

	.download-container .qr-list {
		gap: 0px;
	}
}

@media (max-width: 768px) {
	.download-container {
		padding: 0 20px;
	}

	.download-container .title {
		font-size: 32px;
		text-align: center;
	}

	.download-container .qr-list {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.download-container .qr-list li:nth-child(2) {
		position: relative;
		margin-top: 24px;
	}

	.download-container .qr-list li:nth-child(2)::before {
		top: -24px;
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background: linear-gradient(270deg,
				rgba(255, 255, 255, 0) 0%,
				#fff 50.34%,
				rgba(255, 255, 255, 0) 100%);
	}
}