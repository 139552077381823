.veta-container {
	max-width: 1200px;
	margin: 0 auto;

	/*padding-bottom: 100px;*/
}

/*focus-en*/
.veta-container .veta-focus {
	display: flex;
	text-align: center;
	align-items: center;
	padding-top: 16%;
	position: relative;
	z-index: 1;
}

.veta-container .veta-focus::before {
	max-width: 1000px;
	width: 1000px;
	height: 540px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	top: 0;
	z-index: 1;
	content: '';
	background: url(../../images/veta-bg.png) no-repeat top center;
	background-size: 100%;
}

.veta-container .veta-focus figcaption {
	position: relative;
	z-index: 2;
	width: 100%;
	padding: 100px 0;
	height: 277px;
	background: linear-gradient(180deg,
			rgba(13, 1, 40, 0) 0%,
			rgba(13, 1, 40, 0.43) 11.98%,
			rgba(13, 1, 40, 0.76) 29.69%,
			#0d0128 100%);
}

.veta-container .veta-focus figcaption .logo {
	height: 64px;
}

.veta-container .veta-focus figcaption .logo img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.veta-container .veta-focus figcaption .title {
	max-width: 780px;
	padding: 0 20px;
	margin: 34px auto 0;
	color: #ececec;
	text-align: center;
	/* font-family:FZZhongKai-B08T; */
	font-size: 48px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.veta-container .veta-focus figcaption .sub-title {
	margin-top: 34px;
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

/*focus-zh*/
.veta-container .veta-focus-1 {
	display: flex;
	text-align: center;
	align-items: center;
	padding-top: 16%;
	position: relative;
	z-index: 1;
}

.veta-container .veta-focus-1::before {
	max-width: 1000px;
	width: 1000px;
	height: 540px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	top: 0;
	z-index: 1;
	content: '';
	background: url(../../images/veta-zh-bg.png) no-repeat top center;
	background-size: 100%;
}

.veta-container .veta-focus-1 figcaption {
	position: relative;
	z-index: 2;
	width: 100%;
	padding: 100px 0;
	height: 277px;
	background: linear-gradient(180deg,
			rgba(13, 1, 40, 0) 0%,
			rgba(13, 1, 40, 0.43) 11.98%,
			rgba(13, 1, 40, 0.76) 29.69%,
			#0d0128 100%);
}

.veta-container .veta-focus-1 figcaption .logo {
	height: 64px;
}

.veta-container .veta-focus-1 figcaption .logo img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.veta-container .veta-focus-1 figcaption .title {
	max-width: 780px;
	padding: 0 20px;
	margin: 34px auto 0;
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 48px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.veta-container .veta-focus-1 figcaption .sub-title {
	margin-top: 34px;
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.veta-container .veta-focus-1 figcaption .sub-titleEn {
	font-size: 24px;
}


/*focus-tr*/


.veta-container .veta-focus-2 {
	display: flex;
	text-align: center;
	align-items: center;
	padding-top: 16%;
	position: relative;
	z-index: 1;
}

.veta-container .veta-focus-2::before {
	max-width: 1000px;
	width: 1000px;
	height: 540px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	top: 0;
	z-index: 1;
	content: '';
	background: url(../../images/veta-tr-bg.png) no-repeat top center;
	background-size: 100%;
}

.veta-container .veta-focus-2 figcaption {
	position: relative;
	z-index: 2;
	width: 100%;
	padding: 100px 0;
	height: 277px;
	background: linear-gradient(180deg,
			rgba(13, 1, 40, 0) 0%,
			rgba(13, 1, 40, 0.43) 11.98%,
			rgba(13, 1, 40, 0.76) 29.69%,
			#0d0128 100%);
}

.veta-container .veta-focus-2 figcaption .logo {
	height: 64px;
}

.veta-container .veta-focus-2 figcaption .logo img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.veta-container .veta-focus-2 figcaption .title {
	max-width: 780px;
	padding: 0 20px;
	margin: 34px auto 0;
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 48px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.veta-container .veta-focus-2 figcaption .sub-title {
	margin-top: 34px;
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.veta-container .veta-focus-2 figcaption .sub-titleEn {
	font-size: 24px;
}



/* 第二模块 */

.veta-container .veta-list {
	margin: 0 auto;
	max-width: 1200px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 35px;
}

.veta-container .veta-list .list-item {
	margin: 32px 0;
	display: flex;
	padding: 114px 48px 48px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 40px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.5) inset,
		0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.3);
	position: relative;


}

.veta-container .veta-list .list-item::before {
	position: absolute;
	left: 10px;
	top: 10px;
	content: '';
	width: 120px;
	height: 120px;
}

.veta-container .veta-list .list-item:nth-child(1)::before {
	background: url(../../images/icon-1.png) no-repeat center center;
	background-size: 100%;
}

.veta-container .veta-list .list-item:nth-child(2)::before {
	background: url(../../images/icon-2.png) no-repeat center center;
	background-size: 100%;
}

.veta-container .veta-list .list-item:nth-child(3)::before {
	background: url(../../images/icon-3.png) no-repeat center center;
	background-size: 100%;
}

.veta-container .veta-list .list-item .title {
	color: #ececec;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
}

.veta-container .veta-list .list-item .desc {
	color: rgba(236, 236, 236, 0.7);
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.veta-container .veta-tabs,
.veta-container .veta-tabs-m {
	display: flex;
	padding: 60px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	max-width: 1200px;
	border-radius: 40px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.5) inset,
		0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.3);
}

.veta-container .veta-tabs-m {
	display: none;
}

.veta-container .veta-tabs-m .tab {
	color: #ececec;
	text-align: left;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
	position: relative;
	border-bottom: 4px solid transparent;
	padding-bottom: 4px;
	cursor: default;
}

.veta-container .veta-tabs .tab {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 80px;
	align-self: stretch;
	margin-bottom: 40px;
}

.veta-container .veta-tabs .tab span {
	color: #ececec;
	text-align: center;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
	position: relative;
	border-bottom: 4px solid transparent;
	padding-bottom: 4px;
	cursor: default;
}

.veta-container .veta-tabs .tab span.active {
	color: #246cf9;
	border-bottom: 4px solid #246cf9;
}

.veta-container .veta-tabs .tab-content {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 24px;
}

.veta-container .veta-tabs-m .tab-content {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 24px;
}

.veta-container .veta-tabs .tab-content .list-item,
.veta-container .veta-tabs-m .tab-content .list-item {
	min-height: 360px;
	padding: 20px;
	background: url(../../images/tab-icon-1.png) no-repeat 20px top;
	background-size: 94px;
	display: none;
}

.veta-container .veta-tabs .tab-content .list-item.active {
	display: block;
}

.veta-container .veta-tabs .tab-content .list-item:nth-child(2),
.veta-container .veta-tabs-m .tab-content-0 .list-item:nth-child(2) {
	background: url(../../images/tab-icon-2.png) no-repeat 20px top;
	background-size: 94px;
}

.veta-container .veta-tabs .tab-content .list-item:nth-child(3),
.veta-container .veta-tabs-m .tab-content-1 .list-item:nth-child(1) {
	background: url(../../images/tab-icon-3.png) no-repeat 20px top;
	background-size: 94px;
}

.veta-container .veta-tabs .tab-content .list-item:nth-child(4),
.veta-container .veta-tabs-m .tab-content-1 .list-item:nth-child(2) {
	background: url(../../images/tab-icon-4.png) no-repeat 20px top;
	background-size: 94px;
}

.veta-container .veta-tabs .tab-content .list-item .title,
.veta-container .veta-tabs-m .tab-content .list-item .title {
	color: #ececec;
	/*font-family:FZZhongKai-B08TJinBuTi;*/
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	margin-top: 118px;
}

.veta-container .veta-tabs .tab-content .list-item .sub-title,
.veta-container .veta-tabs-m .tab-content .list-item .sub-title {
	color: #ececec;
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	margin-top: 24px;
}

.veta-container .veta-tabs .tab-content .list-item .text:nth-of-type(1) {
	margin-top: 24px;
}

.veta-container .veta-tabs .tab-content .list-item .text,
.veta-container .veta-tabs-m .tab-content .list-item .text {
	color: rgba(236, 236, 236, 0.6);
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	margin-top: 16px;
	position: relative;
	padding-left: 24px;
}

.veta-container .veta-tabs .tab-content .list-item .text::before,
.veta-container .veta-tabs-m .tab-content .list-item .text::before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	width: 16px;
	height: 16px;
	background: url(../../images/text-icon.png) no-repeat center center;
	background-size: 16px;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

.veta-container .enter-btn-wrap {
	margin-top: 56px;
	text-align: center;
}

.veta-container .enter-btn {
	overflow: hidden;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	display: inline-block;
	background: var(--2, linear-gradient(93deg, #2F6FEF 49.3%, #1E2FCA 87.76%));
	box-shadow: 0px 0px 12px 0px rgba(162, 45, 247, 0.24), 0px 0px 24px 12px rgba(162, 45, 247, 0.10);
	position: relative;
	z-index: 1;
}

.veta-container .enter-btn::before {
	background-color: #3d0b84;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 3px;
	top: 3px;
	content: '';
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
}

.veta-container .enter-btn .btn {
	position: relative;
	z-index: 2;
	color: #e9deff;
	/* font-family:FZZhongKai-B08T; */
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	/*text-transform: uppercase;*/
	display: inline-flex;
	padding: 10px 64px 10px 34px;
	align-items: center;
	gap: 14px;
}

.veta-container .enter-btn .btn::after {
	position: absolute;
	right: 20px;
	content: '';
	width: 34px;
	height: 28px;
	background: url(../../images/veta-enter-arr.png) no-repeat right center;
	background-size: 34px auto;
}

@media (max-width: 1250px) {
	.veta-container {
		padding: 0 20px;
	}
}

/* ipad */
@media (max-width: 1100px) {
	.veta-container {
		.veta-focus {
			&::before {
				width: 720px;
				height: 375px;
			}
		}

		.veta-focus figcaption .title {
			max-width: 600px;
			font-size: 36px;
		}

		.veta-focus figcaption .sub-title {
			font-size: 18px;
		}

		.veta-focus figcaption .logo {
			height: 40px;
		}
	}

	.veta-container {
		.veta-focus-1 {
			&::before {
				width: 720px;
				height: 375px;
			}
		}

		.veta-focus-1 figcaption .title {
			max-width: 600px;
			font-size: 36px;
		}

		.veta-focus-1 figcaption .sub-title {
			font-size: 18px;
		}

		.veta-focus-1 figcaption .logo {
			height: 40px;
		}
	}

	.veta-container {
		.veta-focus-2 {
			&::before {
				width: 720px;
				height: 375px;
			}
		}

		.veta-focus-2 figcaption .title {
			max-width: 600px;
			font-size: 36px;
		}

		.veta-focus-2 figcaption .sub-title {
			font-size: 18px;
		}

		.veta-focus-2 figcaption .logo {
			height: 40px;
		}
	}
}

@media (max-width: 990px) {
	.veta-container .veta-list {
		margin-top: 20px;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 24px;
	}

	.veta-container .veta-list .list-item {
		margin: 0;
	}

	.veta-container .veta-list .list-item .title {
		font-size: 24px;
	}

	.veta-container .veta-list .list-item .desc {
		font-size: 14px;
	}

	.veta-container .veta-tabs {
		display: none;
	}

	.veta-container .veta-tabs-m {
		display: block;
		margin: 24px 0;
		padding: 48px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item {
		display: block;
		min-height: auto;
	}

	.veta-container .veta-tabs-m .tab:nth-of-type(2) {
		margin-top: 48px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(1),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(2),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(3),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(4) {
		background-position: 20px 40px;
		background-size: 64px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item .title,
	.veta-container .veta-tabs-m .tab {
		font-size: 24px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item .sub-title {
		font-size: 16px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item .text {
		font-size: 14px;
	}
}

/* 移动端 */
@media (max-width: 768px) {
	.veta-container{
		margin-bottom: 100px;
	}
	.veta-container .veta-list {
		grid-gap: 60px;
	}

	.veta-container .veta-list .list-item {
		padding: 90px 24px 24px;


	}

	.veta-container .veta-list .list-item::before {
		left: -12px;
		top: -6px;
	}


	.veta-container .veta-focus {
		padding-top: 10%;
	}

	.veta-container .veta-focus::before {
		width: 100%;
		height: 100%;
	}

	.veta-container .veta-focus figcaption .title {
		max-width: 500px;
		font-size: 24px;
	}

	.veta-container .veta-focus figcaption .sub-title {
		font-size: 16px;
	}

	.veta-container .veta-focus figcaption {
		height: 200px;
		padding: 70px 0 44px;
	}



	.veta-container .veta-focus-1 {
		padding-top: 10%;
	}

	.veta-container .veta-focus-1::before {
		width: 100%;
		height: 100%;
	}

	.veta-container .veta-focus-1 figcaption .title {
		max-width: 500px;
		font-size: 24px;
	}

	.veta-container .veta-focus-1 figcaption .sub-title {
		font-size: 16px;
	}

	.veta-container .veta-focus-1 figcaption {
		height: 200px;
		padding: 70px 0 44px;
	}




	.veta-container .veta-focus-2 {
		padding-top: 10%;
	}

	.veta-container .veta-focus-2::before {
		width: 100%;
		height: 100%;
	}

	.veta-container .veta-focus-2 figcaption .title {
		max-width: 500px;
		font-size: 24px;
	}

	.veta-container .veta-focus-2 figcaption .sub-title {
		font-size: 16px;
	}

	.veta-container .veta-focus-2 figcaption {
		height: 200px;
		padding: 70px 0 44px;
	}




	.veta-container .veta-tabs-m {
		display: block;
		margin: 60px 0 0;
		padding: 24px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(1),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(2),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(3),
	.veta-container .veta-tabs-m .tab-content .list-item:nth-child(4) {
		background-position: 0 20px;
		background-size: 64px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item {
		padding: 0px;
	}

	.veta-container .veta-tabs-m .tab-content .list-item .title {
		margin-top: 100px;
	}
}