header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1328px;
	padding: 20px 0;
	margin: 0 auto;
	box-sizing: border-box;
}

header .logo {
	height: 32px;
}

header .logo img {
	width: auto;
	height: 100%;
	object-fit: contain;
	display: block;
}

header .btn {
	display: flex;
	align-items: center;
	gap: 24px;
	position: relative;

}

header .language {
	width: 30px;
	height: 30px;
	background: url(../../images/earth.png) no-repeat right center;
	background-size: 24px 24px;
	cursor: pointer;
}

header .download {
	width: 126px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 60px;
	background: var(--1, conic-gradient(from 180deg at 50% 50%, #7D6CFC 0deg, #FB84F9 104.99999642372131deg, #39A4E4 258.75deg, #7D6CFC 360deg));
	box-shadow: 0px 0px 3.14815px 0px rgba(162, 45, 247, 0.10), 0px 0px 6.51852px 0px rgba(162, 45, 247, 0.10), 0px 0px 13px 0px rgba(162, 45, 247, 0.10), 0px 0px 25.48148px 0px rgba(162, 45, 247, 0.10), 0px 0px 46.85185px 0px rgba(162, 45, 247, 0.10), 0px 0px 19px 9px rgba(162, 45, 247, 0.10);
	position: relative;
	z-index: 1;

}

header .download::before {
	background-color: #3d0b84;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	left: 2px;
	top: 2px;
	content: '';
	border-radius: 60px;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	-ms-border-radius: 60px;
	-o-border-radius: 60px;
	cursor: pointer;
}

header .download span {
	color: #ececec;
	/* font-family: DingTalk JinBuTi; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	position: relative;
	z-index: 2;
	cursor: pointer;
}

header .downloadTr span {
	font-family: 'FZZhongKai-B08T';
}

header .change-language {
	position: absolute;
	top: 46px;
	left: -78px;
	display: inline-flex;
	padding: 12px;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	border-radius: 16px;
	background: #0d0128;
}

header .change-language span {
	color: #ececec;
	font-family: 'FZZhongKai-B08T';
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 4px 8px;
	border-radius: 8px;
	display: block;
	width: 100%;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
}

header .change-language span.active,
header .change-language span.active:hover {
	background: #3d0b84;
}


header .change-language span:hover {
	background: rgba(61, 11, 132, 0.5);
}

@media (max-width: 1250px) {
	header {
		padding: 20px;
	}
}

@media (max-width: 768px) {
	header .logo {
		height: 24px;
	}

	header .btn {
		display: flex;
		align-items: center;
		gap: 12px;
		position: relative;

	}


	header .download {
		width: 102px;
		height: 30px;
		line-height: 30px;
	}

	header .change-language {
		top: 40px;
		left: -64px;
	}

	header .change-language span {
		font-size: 16px;
	}

}