.home-container {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	z-index: 1;
	flex: 1;
	background: #0D0128;
	font-family: FZZhongKai-B08T;
}
.home-container1 {
	font-family: inherit !important;
}

.home-container::after {
	position: absolute;
	z-index: 0;
	left: 0;
	top: 0;
	content: '';
	width: 100%;
	height: 700px;
	background: linear-gradient(0deg,
			#0d0128 0%,
			#0f012c 14.05%,
			#120139 29.86%,
			rgba(21, 2, 66, 0.93) 48.74%,
			rgba(29, 1, 94, 0.88) 69.83%,
			rgba(58, 0, 188, 0.9) 100%),
		url(../images/banner-bg.jpg) no-repeat top center;
	background-size: cover;
}

.home-container .main {
	position: relative;
	z-index: 2;
	padding-bottom: 32px;
}

.home-container .main .focus {
	max-width: 1200px;
	margin: 170px auto 250px;
	text-align: center;
}

.home-container .main .focus .title {
	color: #fff;
	text-align: center;
	text-shadow: 1px 1px 4px #5d2ec8;
	/*font-family: DingTalk JinBuTi;*/
	font-size: 72px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
}

.home-container .main .focus .sub-title {
	margin-top: 24px;
	color: rgba(236, 236, 236, 0.65);
	text-align: center;
	/*font-family: DingTalk JinBuTi;*/
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}
.home-container .main .focus .sub-titleEn{
	font-size: 24px;
}

.home-container .main .focus .docsTitle{
	margin: 24px auto 0px;
	color: #FFFFFF;
	font-size: 24px;
	border: 1px solid #FFFFFF;
	width: 126px;
	height: 48px;
	gap: 0px;
	line-height: 48px;
	cursor: pointer;
}
@media (max-width: 1200px) {
	.home-container .main .focus {
		padding: 0 20px;
	}
}

/* ipad */
@media (max-width: 1100px) {

	.home-container .main .focus .title {
		font-size: 48px;
	}

	.home-container .main .focus .sub-title {
		font-size: 18px;
	}.home-container .main .focus .docstitle {
		font-size: 18px;
	}
}

/* 移动端 */
@media (max-width: 860px) {
	.home-container .main .focus .title {
		font-size: 36px;
	}

	.home-container .main .focus .sub-title {
		font-size: 16px;
	}.home-container .main .focus .docstitle {
		font-size: 18px;
	}
}

/* 移动端 再小 */
@media (max-width: 768px) {
	.home-container .main .focus .title {
		font-size: 34px;
	}

	.home-container .main .focus {
		margin: 90px auto 80px;
	}
}