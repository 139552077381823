.privacy-container {
    text-align: center;
    max-width: 1150px;
    padding: 0 24px;
    border-radius: 60px;
    background: var(--1,conic-gradient(from 0deg at 50% 50%, #FF5664 -37.16deg, #2BB4F5 138.85deg, #FF5664 322.84deg, #2BB4F5 498.85deg)) ;
    box-shadow: 0px 0px 3.14815px 0px rgba(162, 45, 247, 0.10), 0px 0px 6.51852px 0px rgba(162, 45, 247, 0.10), 0px 0px 13px 0px rgba(162, 45, 247, 0.10), 0px 0px 25.48148px 0px rgba(162, 45, 247, 0.10), 0px 0px 46.85185px 0px rgba(162, 45, 247, 0.10), 0px 0px 19px 9px rgba(162, 45, 247, 0.10);
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
.privacy-container:before{
    background:url("../../images/Vector.png") right 2%  top 5%  no-repeat,  rgba(0, 0, 0, 1) url('../../images/Mask group.png')  no-repeat;
    background-size: 10% , cover;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    left: 2px;
    top: 2px;
    content: '';
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
}
.privacy-container .content{
    position: relative;
    z-index: 2;
    padding: 48px 36px;
}

.privacy-container .content .title {
    font-family: FZZhongKai-B08T;
    font-size: 48px;
    font-style: italic;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: 0px;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(93.38deg, #19D3E8 30.97%, #399BFF 37.23%, #8F51FF 43.48%, #E949FF 49.74%, #F958A5 55.99%, #FF5660 62.25%, #FF9632 68.5%, rgba(217, 217, 217, 0) 174.76%);
    /*background: linear-gradient(93.38deg, #19D3E8 30.97%, #399BFF 37.23%, #8F51FF 43.48%, #E949FF 49.74%, #F958A5 55.99%, #FF5660 62.25%, #FF9632 68.5%, rgba(217, 217, 217, 0) 174.76%);*/
    margin-bottom: 40px;
    background-size: 100% 100%;
}

.privacy-container .content .desc{
    font-family: FZZhongKai-B08T;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(236, 236, 236, 1);

}

@media (max-width: 1250px) {
    .privacy-container{
        max-width: 12000px;
        padding: 0 24px;
    }
}
@media (max-width: 1100px) {
    .privacy-container{
        max-width: 780px;
        padding: 0 24px;
    }
}
@media (max-width: 980px) {
    .privacy-container{
        margin-top: 20px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
}
@media (max-width: 768px) {
    .privacy-container{
        margin: 0 24px;
        /*top: 80px;*/
    }
    .privacy-container:before{
        background:url("../../images/Vector.png") right 15%  top 5%  no-repeat,  rgba(0, 0, 0, 1) url('../../images/Mask group.png') top no-repeat;
        background-size: 20% , cover;

    }
    .privacy-container .content{
        padding: 48px 8px;
    }
    .privacy-container .content .title{
        font-size: 36px;
        /*padding: 0 15px;*/
        margin-bottom: 10px;
    }
    .privacy-container .content .desc{
        font-size: 16px;
    }
}