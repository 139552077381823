footer {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 0;
}

footer p {
	color: #939393;
	/* font-family: DingTalk JinBuTi; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

footer .logo {
	height: 32px;
}

footer .logo img {
	height: 32px;
	width: auto;
	object-fit: contain;
}
footer .link{
	display: flex;
}
footer .link img {
	width: 32px;
	margin: 0 0.5rem;
	cursor: pointer;
}
@media (max-width: 1200px) {
	footer {
		padding: 0 20px;
		position: relative;
	}
	footer .link{
		display: flex;
		position: absolute;
		left: 1%;
		top: -100%;
	}
}

@media (max-width: 768px) {
	footer {
		display: flex;
		flex-direction: column-reverse;
		/*align-items: flex-start;*/
		gap: 20px;
		position: relative;
	}
	footer .link{
		display: flex;
		position: absolute;
		left: 3%;
		top: 1%;
	}
	footer .logo{
		margin-left: 55%;
	}
	footer .link img {
		width: 32px;
		margin: 0 0.2rem;
	}
}